<div class="container-fluid">
    <h1>Core Committee</h1>        
    <div class="row">        
        <!-- <div class="col-md-2"></div>         -->
        <div class="col-md-3">  
            <div class="card">
                <div class="card-body">
                    <img src={{management[0].image}} class="card-img-top" alt="...">
                </div>
                <div class="card-title">
                    <h4 class="text-center">
                        {{management[0].name}}<br/>
                        {{management[0].position}}
                    </h4> 
                </div>
            </div>                                                    
        </div>
        <div class="col-md-3">  
            <div class="card">
                <div class="card-body">
                    <img src={{management[1].image}} class="card-img-top" alt="...">
                </div>
                <div class="card-title">
                    <h4 class="text-center">
                        {{management[1].name}}<br/>
                        {{management[1].position}}
                    </h4> 
                </div>
            </div>                                                    
        </div>
        <div class="col-md-3">  
            <div class="card">
                <div class="card-body">
                    <img src={{management[2].image}} class="card-img-top" alt="...">
                </div>
                <div class="card-title">
                    <h4 class="text-center">
                        {{management[2].name}}<br/>
                        {{management[2].position}}
                    </h4> 
                </div>
            </div>                                                    
        </div>
        <div class="col-md-3">  
            <div class="card">
                <div class="card-body">
                    <img src={{management[3].image}} class="card-img-top" alt="...">
                </div>
                <div class="card-title">
                    <h4 class="text-center">
                        {{management[3].name}}<br/>
                        {{management[3].position}}
                    </h4> 
                </div>
            </div>                                                    
        </div>
        <!-- <div class="col-md-2"></div>         -->
    </div>
</div>