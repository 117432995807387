<footer class="footer page-footer font-small mdb-color">  
  <div class="container-fluid text-center text-md-left">    
    <div class="row text-center text-md-left mt-3">      
      <div class="col-md-2 col-lg-2 col-xl-2 mx-auto mt-3"></div>
      <div class="col-md-2 col-lg-2 col-xl-2 mx-auto mt-3">
        <a class="navbar-brand" routerLink="/"><img class="foot-img" height="150" width="150"
            src="assets/images/usluk-logo.png"></a>
      </div>      
      <hr class="w-100 clearfix d-md-none">      
      <div class="col-md-2 col-lg-2 col-xl-2 mx-auto mt-3"></div>
      <div class="col-md-3 col-lg-3 col-xl-3 mx-auto mt-3">
        <h5 class="text-uppercase title-f mb-4">Sitemap</h5>
        <p class="social text-center">
          <a href="/gallery">Gallery</a>                              
        </p>
        <p class="social">
          <a href="/team">Core Team</a>        
        </p>
        <p class="social">
          <a href="/contact-us">Contact Us</a>
        </p>
        <p class="social">
          <a href="/uttarakhand">Uttarakhand</a>
        </p>
        <p class="social">
          <a href="/register">Join USLUK</a>
        </p>
      </div>      
      <hr class="w-100 clearfix d-md-none">      
      <hr class="w-100 clearfix d-md-none">            
      <div class="col-md-3 col-lg-3 col-xl-3 mx-auto mt-3">
        <h5 class="text-uppercase mb-4 title-f">Contact</h5>
        <p class="social" style="text-align: center;">
          <i class="fa fa-home mr-3"></i>
          <a target="_blank" href="https://maps.app.goo.gl/VDAcGHFco4UmswSi6">
            2 Europa Road, Wigston <br/>Leicestershire <br/>United Kingdom, LE18 3YS
          </a>
        </p>
        <p class="social">
          <i class="fa fa-envelope mr-3"></i> 
          <a href="mailto:uttarakhandsamajleicesteruk@gmail.com">uttarakhandsamajleicesteruk@gmail.com</a>
        </p>
        <div class="social">
          <a target="_blank" href="https://www.facebook.com/usluk1"><i class="fa fa-facebook mr-3"></i></a>
          <a target="_blank" href="#"><i class="fa fa-instagram"></i></a>
        </div>
      </div>
      
    </div>
    <!-- Footer links -->
    <hr>
    <!-- Grid row -->
    <div class="row d-flex align-items-center">
      
      <div class="col-md-4">
        <!--Copyright-->
        <p class="text-center text-md-left">Designed By:
          <a class="foot-anchor" target="_blank" href="https://www.jmvl.co.uk">Jai Maa Vaibhav Laxmi Ltd</a>
        </p>
      </div>
      <div class="col-md-4">
        <!--Copyright-->
        <p class="text-center">© Copyright: {{dateNow | date: 'yyyy'}} Uttarakhand Samaj Leicester (UK)</p>
      </div>
    </div>
    <!-- Grid row -->
  </div>
  <!-- Footer Links -->
</footer>
<!-- Footer -->