import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormGroup, FormArray } from '@angular/forms';
import { Service } from '../../services/usluk.service';
import { Router } from '@angular/router';
declare var $: any;

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})

export class RegisterComponent implements OnInit {

  mainApplicantForm: FormGroup;
  spouseForm: FormGroup;
  childrenForm: FormGroup;
  submitted = false;
  isInvalid = false;
  isNoHouseNumber = false;
  gender = 'Male';
  isSpouse = false;
  isMarried = false;
  isChild = false;
  isAdult = false;  
  postcode = '';
  latitude: number;
  longitude: number;
  maritalStatus = '';
  public message: string;
  addressList = [];
  addressNotFound = false;
  name: any;

  constructor(
    private router: Router,
    private service: Service,
    private formBuilder: FormBuilder
  ) { }

  ngOnInit(): void {    
    this.mainApplicantForm = this.formBuilder.group({      
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],      
      email: ['', [Validators.required, Validators.email]],
      phone: ['', Validators.required],
      whatsApp: [true],
      gender: ['Male', Validators.required],
      dob: ['', Validators.required],
      houseNumber: ['', Validators.required],
      postcode: ['', Validators.required],
      address: ['', Validators.required],
      occupation: ['', Validators.required],
      town:[''],
      district: [''],
      maritalStatus: ['', Validators.required],
      anniversary: [''],
      isSpouse: [false],      
      isChild: ['No'],
      acceptTerms: [false, Validators.requiredTrue],
      declaration: [false, Validators.requiredTrue]      
    });

    this.spouseForm = this.formBuilder.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],      
      email: ['', [Validators.required, Validators.email]],
      phone: ['', Validators.required],
      whatsApp: [true],
      spousegender: ['Female', Validators.required], 
      dob: ['', Validators.required],     
      occupation: ['', Validators.required],
      town:[''],
      district: ['']
    });

    this.childrenForm = this.formBuilder.group({
      children: this.formBuilder.array([])
    });
  }

  get f() { return this.mainApplicantForm.controls; }
  get fs() { return this.spouseForm.controls; }
  get fc() { return this.childrenForm.controls; }

  get children() : FormArray {
    return this.childrenForm.get('children') as FormArray
  }

  newChild(): FormGroup {
    return this.formBuilder.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],  
      childgender: ['Male', Validators.required],
      dob: ['', Validators.required]      
    });
  }

  onChange(value) {
    //console.log(value);
    //console.log(this.childrenForm.value);
  }

  addChild() {        
    this.children.push(this.newChild());
  }

  removeChild(i: number) {
    this.children.removeAt(i);
  }

  checkIfAdult(dob){        
    var age = new Date().getFullYear() - new Date(dob).getFullYear();    
    //console.log('Age: ', age);       
    if (age >= 18) 
      return true;
    return false;
  }

  register() {
    this.submitted = true;                     
    if (this.mainApplicantForm.invalid) {
      //console.log('Main Form is invalid');      
      return;
    }
    if (this.isSpouse && this.spouseForm.invalid) {
      //console.log('Spouse Form is invalid');     
      return;
    } 
    if (this.isChild && this.childrenForm.invalid) {
      //console.log('Child form is invalid.');
      return;
    }
    
    this.mainApplicantForm.value.firstName = this.capitalize(this.mainApplicantForm.value.firstName);
    this.mainApplicantForm.value.lastName = this.capitalize(this.mainApplicantForm.value.lastName);

    this.mainApplicantForm.value.email = this.mainApplicantForm.value.email.toLowerCase();
    this.mainApplicantForm.value.isSpouse = this.isSpouse;

    if (this.isSpouse) {
      this.spouseForm.value.email = this.spouseForm.value.email.toLowerCase();
      this.spouseForm.value.firstName = this.capitalize(this.spouseForm.value.firstName);
      this.spouseForm.value.lastName = this.capitalize(this.spouseForm.value.lastName);
    }

    this.childrenForm.value.children.forEach(child => {
      child.firstName = this.capitalize(child.firstName);
      child.lastName = this.capitalize(child.lastName);
      if(this.checkIfAdult(child.dob))
        this.isAdult = true;
    });

    this.mainApplicantForm.value.postcode = this.postcode;
    this.mainApplicantForm.value.latitude = this.latitude;
    this.mainApplicantForm.value.longitude = this.longitude; 
    //console.log('Forma are Valid');
    //console.log("Main Form: ", this.mainApplicantForm.value);
    //console.log("Spouse Form: ", this.spouseForm.value);
    //console.log("Children: ", this.childrenForm.value);

    const param = {
      mainApplicant: this.mainApplicantForm.value,
      spouse: this.spouseForm.value,
      children: this.childrenForm.value
    };
    
    this.service.registerMembers(param).subscribe((res: any) => {
      $('#staticBackdrop').modal('show');
      this.mainApplicantForm.reset();   
      this.spouseForm.reset();
      this.childrenForm.reset();     
      this.submitted = false;
    });
  }

  close() {
    $('#staticBackdrop').modal('hide');
    this.router.navigate(['/']);
  }

  donate() {
    $('#staticBackdrop').modal('hide');
    this.router.navigate(['/donate']);
  }

  validatePostcode(postcode) {    
    this.postcode = postcode.toUpperCase();
    this.service.validatePostcode(this.postcode).subscribe((res: any) => {
      this.isInvalid = !res.result;
      if (!this.isInvalid) {
        this.postcode = postcode;
        this.getLatLong();
      }        
    });
    //console.log("Postcode: ", this.mainApplicantForm.value.postcode);
  }

  capitalize(nameString) {    
    if (typeof nameString !== 'string') return '';
    var nameArr = nameString.split(" ");  
    //console.log('Name: ', nameString);
    nameString = '';  
    nameArr.forEach(name => {
      name = name.toLowerCase(); 
      name = name.charAt(0).toUpperCase() + name.slice(1);
      nameString += name + ' ';
    });
    nameString = nameString.trim();
    //console.log('Formated Name: ', nameString);
    return nameString;
  }

  getLatLong() {
    this.service.postcodeLookup(this.postcode).subscribe((res: any) => {
      if (res.status === 200) {              
        this.latitude = res.result.latitude;
        this.longitude = res.result.longitude;
      } else {
        //console.log("Error: ", res.error);
      }      
    });
  }

  getAddress() {
    this.postcode = this.postcode.toUpperCase();
    this.isNoHouseNumber = this.mainApplicantForm.value.houseNumber === '' ? true : false;
    var addressRequestObj = {
      houseNumber: this.mainApplicantForm.value.houseNumber,
      postcode: this.postcode,
      latitude: this.latitude,
      longitude: this.longitude
    }    
    //console.log("addressRequestObj = ", addressRequestObj);
    this.service.getAddress(addressRequestObj).subscribe((response: any) => {
      //console.log('Response: ', response);
      if (response.statusCode === 200) {
        this.addressList = response.body.addresses; 
        this.addressNotFound = this.addressList.length === 0 ? true:false;              
      } 
      //console.log('Address List: ', this.addressList);
    }); 
  }

  getGender(gender) {
    this.gender = gender;    
  }

  getMaritalStatus(maritalStatus) {
    this.maritalStatus = maritalStatus;
    if (maritalStatus === 'Married') {
      this.isMarried = true;
      this.isSpouse = true;
    } else if (maritalStatus === 'Widower' || maritalStatus === 'Widow' || maritalStatus === 'Divorced') {
      this.isMarried = true;
      this.isSpouse = false;
    } else {
      this.isMarried = false;
      this.isSpouse = false;
    }
  }

  childYesNo(result) {
    if (result === 'Yes') 
      this.isChild = true;
    else if (result === 'No')
      this.isChild = false;   
    //console.log('isChild: ', this.isChild); 
  }
}