import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Service } from '../../services/usluk.service';

declare var $: any;

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit {

  contactForm: FormGroup;
  submitted = false;

  constructor(
    private service: Service,
    private formbuilder: FormBuilder,
  ) { }

  ngOnInit(): void {
    this.contactForm = this.formbuilder.group({
      name: ['', Validators.required],
      phone: ['', Validators.required],
      email: ['', [
        Validators.required,
        Validators.pattern(/^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/)]
      ],
      message: ['']
    });
  }

  get f() { return this.contactForm.controls; }

  openContactForm() {
    //console.log('Option: ', this.option);
    $('#contactBackdrop').modal('show');
  }

  onSubmit() {
    this.submitted = true;
    if(this.contactForm.invalid){
      //console.log('Form invalid: ', this.contactForm.value);
      return;
    }

    //console.log(this.contactForm.value);
    this.service.sendMessage(this.contactForm.value).subscribe((res: any) => {
      $('#contactBackdrop').modal('hide');
      $('#staticBackdrop').modal('show');
      this.contactForm.reset();
    });
  }
}