import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Service } from '../../services/usluk.service';
import { environment } from 'src/environments/environment';
declare var Swiper: any;
declare var $: any;
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  Swiper: any;

  s3AssetsBaseURL = `${environment.s3AssetsBaseURL}`;  
  s3GalleryBaseURL = `${environment.s3GalleryBaseURL}`;

  eventFlyer = this.s3AssetsBaseURL + "event-flyer.jpeg";
  eventBanner = this.s3AssetsBaseURL + "event-banner.jpeg";
  home1 = this.s3AssetsBaseURL + "home-01.jpeg";  
  home2 = this.s3AssetsBaseURL + "home-02.jpeg";
  home3 = this.s3AssetsBaseURL + "home-03.jpeg";
  home4 = this.s3AssetsBaseURL + "home-04.jpeg";
  
  constructor(
    private router: Router,
    private service: Service
  ) { }

  ngOnInit(): void {
    this.Swiper = new Swiper('.swiper-container', {
      effect: 'coverflow',
      grabCursor: true,
      centeredSlides: true,
      slidesPerView: 'auto',
      coverflowEffect: {
        rotate: 20,
        stretch: 0,
        depth: 200,
        modifier: 1,
        slideShadows: true,
      },
     loop: true,
     autoplay:{
       delay: 1000,
       disableOnInteraction: false,
     }
    });
  }

  public slides = [        
    { img: this.s3AssetsBaseURL + "banner-01.jpeg" },  
    { img: this.s3AssetsBaseURL + "banner-02.jpeg" },
    { img: this.s3AssetsBaseURL + "banner-03.jpeg" },
    { img: this.s3AssetsBaseURL + "banner-04.jpeg" },
    { img: this.s3AssetsBaseURL + "banner-05.jpeg" },
    { img: this.s3AssetsBaseURL + "banner-06.jpeg" },
    { img: this.s3AssetsBaseURL + "banner-07.jpeg" },
    { img: this.s3AssetsBaseURL + "banner-08.jpeg" },
    { img: this.s3AssetsBaseURL + "banner-09.jpeg" },
    { img: this.s3AssetsBaseURL + "banner-10.jpeg" },
    { img: this.s3AssetsBaseURL + "banner-11.jpeg" }
  ];

  public gallery = [
    this.s3GalleryBaseURL + "gallery-01.jpeg",
    this.s3GalleryBaseURL + "gallery-02.jpeg",
    this.s3GalleryBaseURL + "gallery-03.jpeg",
    this.s3GalleryBaseURL + "gallery-04.jpeg",
    this.s3GalleryBaseURL + "gallery-05.jpeg",
    this.s3GalleryBaseURL + "gallery-06.jpeg",
    this.s3GalleryBaseURL + "gallery-07.jpeg",
    this.s3GalleryBaseURL + "gallery-08.jpeg",
    this.s3GalleryBaseURL + "gallery-09.jpeg",
    this.s3GalleryBaseURL + "gallery-10.jpeg",
    this.s3GalleryBaseURL + "gallery-11.jpeg",
    this.s3GalleryBaseURL + "gallery-12.jpeg",
    this.s3GalleryBaseURL + "gallery-13.jpeg",
    this.s3GalleryBaseURL + "gallery-14.jpeg",
    this.s3GalleryBaseURL + "gallery-15.jpeg",
    this.s3GalleryBaseURL + "gallery-16.jpeg",
    this.s3GalleryBaseURL + "gallery-17.jpeg",
    this.s3GalleryBaseURL + "gallery-18.jpeg",
    this.s3GalleryBaseURL + "gallery-19.jpeg",
    this.s3GalleryBaseURL + "gallery-20.jpeg",
    this.s3GalleryBaseURL + "gallery-21.jpeg",
    this.s3GalleryBaseURL + "gallery-22.jpeg",
    this.s3GalleryBaseURL + "gallery-23.jpeg",
    this.s3GalleryBaseURL + "gallery-24.jpeg",
    this.s3GalleryBaseURL + "gallery-25.jpeg",
    this.s3GalleryBaseURL + "gallery-26.jpeg",
    this.s3GalleryBaseURL + "gallery-27.jpeg",
    this.s3GalleryBaseURL + "gallery-28.jpeg",
    this.s3GalleryBaseURL + "gallery-29.jpeg",
  ];

  public slideConfig = {
    infinite: true,
    speed: 600,
    fade: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    dots: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3
        }
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 586,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  bookTickets() {
    this.router.navigate(['/book-tickets']);
  }
}