<section id="banner" class="banner" style="padding-bottom: 10px !important;">
  <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig">
    <div ngxSlickItem *ngFor="let slide of slides" class="slide"
      tyle="border: 1px solid #e6e4e4; border-radius: 10px; box-shadow: 0 0 5px #dcd9d9;">
      <img src="{{ slide.img }}" alt="" width="100%">
    </div>
  </ngx-slick-carousel>
</section>

<section class="paragraph" id="section-1">
  <div class="container-fluid">
    <div class="row align-items-lg-center">
      <div class="col-12 col-md-6 text-center text-md-left" data-aos="fade-right">
        <h1>About Us</h1>
        <p>Uttarakhand Samaj Leicester has been working since 2016 to connect Uttarakhandi in Leicester. Considering the
          growing members Uttarakhand Samaj Leicester - UK (called USLUK also) has been formally registered as charity
          organisation in 2024.</p>

        <p>USLUK members include people having roots in the Uttarakhand, India and living around Leicester, United
          Kingdom. People of Uttarakhand origin live in almost every part of United Kingdom. The other majority living
          areas of Uttarakhandis are in cities
          including London, Birmingham, Nottingham, Leicester, Edinburgh & Glasgow.</p>

        <p>The charity was formed for the promotion of social inclusion for the public benefit for people originating
          from Uttarakhand, in particular, but not exclusively, to establish a strong bond between them. It helps us to
          stay connected with our roots while helping those who arrived in the UK. USLUK is here to help members who are
          socially
          excluded on the grounds of their social and economic position and need support to survive in the society.</p>
      </div>
      <div class="col-12 col-md-6" data-aos="fade-left">
        <img class="img-fluid" src={{home1}} alt="">
      </div>
    </div>
  </div>
</section>

<section class="paragraph" id="section-2">
  <div class="container-fluid">
    <div class="row align-items-lg-center">
      <div class="col-12 col-md-6" data-aos="fade-right">
        <img class="img-fluid" src={{home2}} alt="">
      </div>
      <div class="col-12 col-md-6 text-center text-md-left" data-aos="fade-left">
        <h1 style="font-family: 'alexbrush_reg',sans-serif;">About Work</h1>

        <p>Leicester, United Kingdom is the main working area of the charity. However, we are committed to work with
          other Uttarakhandi organisation in UK as well as to the Uttarakhand region in
          India whic is prone to extreme rainfall, earthquake, landslides, flooding – resulting into calamities and
          hardship to the
          local people who are already impoverished, assistance is provided there as well. Loss of life and of cattle
          (vital
          to the villagers’ livelihood) routinely occurs. Even after relief work carried out by local government
          agencies the
          infra-structure remains rudimentary.</p>

        <p>Our charity team establishes contacts with other Uttarakhandi organisation in UK and Uttarakhand to extend
          our help and
          support.</p>
      </div>
    </div>
  </div>
</section>

<section class="paragraph" id="section-3">
  <div class="container-fluid">
    <div class="row align-items-lg-center">
      <div class="col-12 col-md-6 text-center text-md-left" data-aos="fade-right">
        <h1 style="font-family: 'alexbrush_reg',sans-serif;">Our Charity</h1>

        <p>The charity has a group of dedicated members who volunteer their specialisation in different fields to extend
          the support to community.
          The help is provided on a one-to-one basis or in a group, if appropriate, at hired venues or at a
          volunteer’s home. Guidance is principally given to establish the connectivty with local community and in
          tutoring of English but also providing additional
          educational help. There is also a significant requirement for assistance in addressing employment
          opportunities which
          volunteers do help to meet according to their expertise. The purpose of these aims is to make members and
          non-members who
          seek our help financially independent, less reliant on other benefits and socially stable.</p>

        <p>We aim to communicate with members via different communication channels either is call or social media and
          social events.
          We also hold periodic meetings where members can raise issues which affect them and at which the charity can
          help to address
          the issues.</p>

        <p>Membership of the charity is continually changing so there is an on-going need for communication and help.
          The charity organises public events showcasting the Uttarakhand culture and traditions, Family days out, Holi
          festival and
          Diwali festival which involves the local community and public. These functions promote Uttarakhand culture and
          cultural conservation – most importantly communication in Uttarakhand regional languages Garhwali and Kumaoni
          to keep the
          languages alive. Events including the Uttarakhand dresses, singing pahadi songs, traditional dancing and
          serving traditional food
          during our cultural events is widely appreciated.</p>
      </div>
      <div class="col-12 col-md-6" data-aos="fade-left">
        <img class="img-fluid" src={{home3}} alt="">
      </div>
    </div>
  </div>
</section>

<section class="paragraph" id="section-4">
  <div class="container-fluid">
    <div class="row align-items-lg-center">
      <div class="col-12 col-md-6" data-aos="fade-right">
        <img class="img-fluid" src={{home4}} alt="">
      </div>
      <div class="col-12 col-md-6 text-center text-md-left" data-aos="fade-left">
        <h1>Recent Notable Activities</h1>

        <p>USLUK supported many casue during Covid to help Uttarakhandis who were in need for social, mental or
          financial
          assistance. We worked with other organisations in UK to assist Uttarakhandis in UK. Supported fundraising to
          arrange repartiation of deceased here whose family were in India waiting for their final rituals.</p>

        <p>USLUK joinly organised welcome event for chief Minister Uttarakhand in 2023 to showcast presence of
          Uttarakhandi samaj in UK.</p>
      </div>
    </div>
  </div>
</section>

<section class="gallery">
  <div class="container-fluid">
    <h1>Uttarakhand - At a Glance</h1>
  </div>
  <div class="swiper-container">
    <div class="swiper-wrapper">
      <div class="swiper-slide" *ngFor="let image of gallery"><img src={{image}} /></div>
    </div>
  </div>
</section>

<div class="container fblink">
  <p>If you are in Leicester, UK, Please feel free to join Uttarakhand Samaj Leicester UK<br>
    <a routerLink="/register">Join USLUK</a>
  </p>
</div>