import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Service } from '../../services/usluk.service';
import { DomSanitizer } from '@angular/platform-browser';
declare var $: any;

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.scss']
})
export class GalleryComponent implements OnInit {
  safeUrl = [];
  gallery = [];

  constructor(
    private router: Router,
    private service: Service,
    private domSanitizer: DomSanitizer
  ) { }

  ngOnInit(): void {    
    this.getGallery();    
  }

  getGallery() {
    this.service.getGallery().subscribe((res: any) => {
      if (res.statusCode === 200 ) {
        this.gallery = JSON.parse(res.body);
        this.gallery = this.gallery.filter(item => item.isActive === true);
        //console.log("Video Details: ", this.gallery); 
        for (let i=0; i<this.gallery.length; i++) {
          if (this.gallery[i].isVideo) {
            this.safeUrl.push(this.domSanitizer.bypassSecurityTrustResourceUrl("https://www.youtube.com/embed/" + this.gallery[i].youTubeID));
          } else {
            this.safeUrl.push({});
          }
        }
        //console.log("Safe Url: ", this.safeUrl);
      }            
    });    
  }

  navigateTo() {
    //console.log('redirecting to home page');
    this.router.navigate(['/']);
  }
}
