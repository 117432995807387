<section>
    <div class="container success">
        <div class="row align-items-lg-center">
            <div class="col-12 col-md-3" data-aos="fade-left">
                <img class="img-fluid" src="assets/images/namste.png" alt="">
            </div>
            <div class="col-12 col-md-6">
                <h1>Thanks for your support to USLUK</h1>
                <h2>You will get Confirmation email shortly.</h2>
            </div>
            <div class="col-12 col-md-3" data-aos="fade-left">
                <img class="img-fluid" src="assets/images/namste.png" alt="">
            </div>
        </div>
    </div>
</section>