<div style="text-align: center;">
    <iframe src="https://calendar.google.com/calendar/embed?height=600&wkst=1&ctz=Europe%2FLondon&bgcolor=%23ffffff&showTitle=0&src=NjczZDQ3Yzg3YWEwZTAyZTFmYzlhNjVjY2Y4ZjQwYmM3ZjFhNTk0ZGQ0OWI1NzA3NjRiOWJhMTcxYTNmNTg2ZkBncm91cC5jYWxlbmRhci5nb29nbGUuY29t&src=ZW4udWsjaG9saWRheUBncm91cC52LmNhbGVuZGFyLmdvb2dsZS5jb20&color=%233F51B5&color=%23D50000" style="border:solid 1px #777" width="800" height="600" frameborder="0" scrolling="no"></iframe>

</div>

<div class="container-fluid">
    <h1>Event Calendar</h1> 
    <div class="row">
        <div class="col-md-3"></div>
        <div class="col">
            <div class="row" *ngFor="let event of events;">
                <div class="card">
                    <div class="card-body">
                        <h2>{{event.title}}</h2>
                        <div class="row">
                            <div class="col"><h3>{{event.eventDate}}</h3></div>
                            <div class="col"><h3>{{event.eventTime}}</h3></div>
                        </div>
                        <h4>{{event.venue}}</h4>
                        <h4>{{event.comments}}</h4>
                    </div>                    
                </div>
            </div>
        </div>
        <div class="col-md-3"></div>
    </div>
</div>