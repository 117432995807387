import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './static-pages/home/home.component';
import { ContactUsComponent } from './static-pages/contact-us/contact-us.component';
import { UttrakhandComponent } from './static-pages/uttrakhand/uttrakhand.component';
import { GalleryComponent } from './static-pages/gallery/gallery.component';
import { MembersComponent } from './static-pages/members/members.component';
import { RegisterComponent } from './static-pages/register/register.component';
import { SuccessComponent } from './static-pages/success/success.component';
import { EventCalendarComponent } from './static-pages/event-calendar/event-calendar.component';

const routes: Routes = [
	{ path: '', component: HomeComponent },
  { path: 'team', component: MembersComponent },  
  { path: 'uttrakhand', component: UttrakhandComponent }, 
  { path: 'gallery', component: GalleryComponent },  
  { path: 'contact-us', component: ContactUsComponent }, 
  { path: 'register', component: RegisterComponent },   
  { path: 'success', component: SuccessComponent },
  { path: 'event-calendar', component: EventCalendarComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }