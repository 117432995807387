import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-uttrakhand',
  templateUrl: './uttrakhand.component.html',
  styleUrls: ['./uttrakhand.component.scss']
})
export class UttrakhandComponent implements OnInit {
 
  s3AssetsBaseURL = `${environment.s3AssetsBaseURL}`;  
  s3GalleryBaseURL = `${environment.s3GalleryBaseURL}`;

  constructor() { }

  ngOnInit(): void {}

  public gallery = [
    this.s3GalleryBaseURL + "gallery-01.jpeg",
    this.s3GalleryBaseURL + "gallery-02.jpeg",
    this.s3GalleryBaseURL + "gallery-04.jpeg",
    this.s3GalleryBaseURL + "gallery-06.jpeg",
    this.s3GalleryBaseURL + "gallery-11.jpeg",    
    this.s3GalleryBaseURL + "gallery-13.jpeg",
    this.s3GalleryBaseURL + "gallery-15.jpeg",
    this.s3GalleryBaseURL + "gallery-24.jpeg"
  ];
}