import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AgmCoreModule } from '@agm/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedComponentModule } from './shared-component/shared-component.module';
import { HttpClientModule } from '@angular/common/http';
import { ToastrModule } from 'ngx-toastr';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ScrollTopComponent } from './scroll-top/scroll-top.component';

import { HomeComponent } from './static-pages/home/home.component';
import { ContactUsComponent } from './static-pages/contact-us/contact-us.component';
import { UttrakhandComponent } from './static-pages/uttrakhand/uttrakhand.component';

import { Service } from './services/usluk.service';
import { MembersComponent } from './static-pages/members/members.component';
import { RegisterComponent } from './static-pages/register/register.component';
import { SuccessComponent } from './static-pages/success/success.component';
import { GalleryComponent } from './static-pages/gallery/gallery.component';
import { EventCalendarComponent } from './static-pages/event-calendar/event-calendar.component';
//import { YouTubePlayerModule } from '@angular/youtube-player';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    ContactUsComponent,
    UttrakhandComponent,
    ScrollTopComponent,
    MembersComponent,
    RegisterComponent,    
    SuccessComponent,
    GalleryComponent,
    EventCalendarComponent,
  ],
  imports: [
    NgbModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    SharedComponentModule,
    BrowserAnimationsModule,
    HttpClientModule,
    SlickCarouselModule,    
    ToastrModule.forRoot({ timeOut: 1000 }) // ToastrModule added
  ],
  providers: [
    Service
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }