import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-members',
  templateUrl: './members.component.html',
  styleUrls: ['./members.component.scss']
})
export class MembersComponent implements OnInit {

  s3BaseURL = `${environment.s3AssetsBaseURL}`; 

  management = [{
      name: "Jaypal Singh Rawat",
      position: "Chairman & Founder",
      image: this.s3BaseURL + "JaypalSinghRawat.jpeg"
    }, {
      name: "Deepak Singh Rawat",
      position: "Trustee",
      image: this.s3BaseURL + "DeepakSinghRawat.jpeg"
    }, {
      name: "Mahendra Singh Negi",
      position: "Trustee",
      image: this.s3BaseURL + "MahendraSinghNegi.jpeg"
    }, {
      name: "Ramlal Shyamlal Panchola",
      position: "Trustee",
      image: this.s3BaseURL + "RamlalShyamlalPanchola.jpeg"
    }
  ];

  constructor() { }

  ngOnInit(): void {
    // Fetch the members details from the backend 
    // Seperate them as a Chairman, Secratery, Core Members, Pre Chairmans & Pre Directors

  }
}