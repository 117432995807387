export const environment = {
  production: false,

  webAPIBaseURL: 'https://53j9ro0dxf.execute-api.eu-west-2.amazonaws.com/prod',  
  apiBaseURL: 'https://dxfa7roodd.execute-api.eu-west-2.amazonaws.com/prod/',  
    
  registerAPIUrl: 'https://hg2z1dple1.execute-api.eu-west-2.amazonaws.com/prod/register',
  sendMessageAPIUrl: 'https://6lz1br7q71.execute-api.eu-west-2.amazonaws.com/prod/sendMessage',  

  s3BaseURL: "https://qie407ghx2.execute-api.eu-west-2.amazonaws.com/prod/",  
  s3AssetsBaseURL: "https://qie407ghx2.execute-api.eu-west-2.amazonaws.com/prod/usluk-assets/",  
  s3GalleryBaseURL: "https://qie407ghx2.execute-api.eu-west-2.amazonaws.com/prod/usluk-gallery/",  
};

