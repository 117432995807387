<div class="container-fluid uttarakhand"> 
    <h2>Our Uttarakhand</h2>
</div>
<div class="container uttarakhand-3d">    
    <div class="box">
        <span style="--i:1;"><img src={{gallery[0]}}/></span>
        <span style="--i:2;"><img src={{gallery[1]}}/></span>
        <span style="--i:3;"><img src={{gallery[2]}}/></span>
        <span style="--i:4;"><img src={{gallery[3]}}/></span>
        <span style="--i:5;"><img src={{gallery[4]}}/></span>
        <span style="--i:6;"><img src={{gallery[5]}}/></span>
        <span style="--i:7;"><img src={{gallery[6]}}/></span>
        <span style="--i:8;"><img src={{gallery[7]}}/></span>
        <span style="--i:9;"><img src={{gallery[8]}}/></span>        
    </div>
</div>