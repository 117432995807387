<div class="container-fluid contactus">
    <section class="contact" id="contact">
        <div class="social">            
            <a href="https://www.facebook.com/usluk1"><i class="fa fa-facebook"></i></a>
            <a href="#"><i class="fa fa-instagram"></i></a>
        </div>
        <div class="contact-us">
            <div class="c-heading">                
                <h4>Our Address</h4><br/>
                <p>2 Europa Road
                    <br/>Wigston
                    <br/>Leicestershire
                    <br/>United Kingdom, LE18 3YS
                </p>                
            </div>
            <div class="c-mail">                
                <a href="mailto:uttarakhandsamajleicesteruk@gmail.com">uttarakhandsamajleicesteruk@gmail.com</a>
            </div><br/>
            <button class="buttonBox" type="button" (click)="openContactForm()">Get In Touch</button>
        </div>
        
        <div class="map">            
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2424.617685509961!2d-1.0912869235986167!3d52.57652477207603!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4877668395f72189%3A0xb80e088b8e5dc081!2s2%20Europa%20Rd%2C%20Wigston%20LE18%203YS!5e0!3m2!1sen!2suk!4v1715950276921!5m2!1sen!2suk" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>            
        </div>
    </section>
</div>

<div class="modal fade" id="contactBackdrop" data-backdrop="static" data-keyboard="false" tabindex="-1"
    aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title text-primary" id="staticBackdropLabel">Contact Form</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <form [formGroup]="contactForm" (ngSubmit)="onSubmit()">
                <div class="modal-body">
                    <div class="form-group">                                        
                        <input type="text" class="form-control" id="name" formControlName="name" required="" [ngClass]="{ 'is-invalid': submitted && f.name.errors}" placeholder="Name*">
                        <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
                            <div *ngIf="f.name.errors.required" style="float: right">Name is required</div>
                        </div>
                    </div>
                    <div class="form-group">
                        <input type="number" class="form-control" id="phone" formControlName="phone" [ngClass]="{ 'is-invalid': submitted && f.phone.errors }" placeholder="Contact Number*">
                        <div *ngIf="submitted && f.phone.errors" class="invalid-feedback">
                            <div *ngIf="f.phone.errors.required" style="float: right">Phone no. is required</div>
                        </div>
                    </div>           
                    <div class="form-group">                                        
                        <input type="email" class="form-control" id="email" formControlName="email"
                            required="" [ngClass]="{ 'is-invalid': submitted && f.email.errors}" placeholder="Email*">
                        <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                            <div *ngIf="f.email.errors.required" style="float: right">Email is required</div>
                            <div *ngIf="f.email.errors.pattern" style="float: right">Enter valid email address</div>
                        </div>
                    </div>
                    <div class="form-group">                                        
                        <textarea class="form-control" id="exampleFormControlTextarea1" rows="9" formControlName="message" placeholder="Message"></textarea>
                    </div>
                </div>
                <div class="modal-footer">                    
                    <button style="text-align: center;" type="submit" class="btn btn-primary btn-sm">Send</button>
                </div>
            </form>
        </div>
    </div>
</div>

<div class="modal fade" id="staticBackdrop"  data-backdrop="static" data-keyboard="false" tabindex="-1"
  aria-labelledby="staticBackdropLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="staticBackdropLabel">Success</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body text-center">
        <h6>Your message has been sent successfully. We will get back to you soon.</h6>
        <img style="height:110px; width:150px; " src="assets/images/thanks.png" />        
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary" data-dismiss="modal">OK</button>
      </div>
    </div>
  </div>
</div>

<div class="container fblink">
    <p>If you are in UK, Please feel free to join Uttarakhand Community in UK<br>
        <a routerLink="/register">Join usluk</a>
    </p>
</div>